import React from 'react'
import { IntroSection, 
  PrayerTiming, 
  Slider ,Feature  ,Audio, Progress, Pillers ,AllCourse ,
  Ayat,Navigating,Download
  
} from '../../components'

const Home = () => {
  return (
    <>
      <Slider  />
      <PrayerTiming />
      <IntroSection />
      <Feature />
      <Audio />
      <Progress />
      <Pillers />
      <AllCourse />
      <Ayat />
      <Navigating />
      <Download />
     
    </>
  )
}

export default Home