import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import useAppSelector from '../../Redux/Utilities/useAppSelector';
import { FetchPrayer } from '../../Redux/Slices/Prayer';
import moment from 'moment';

const PrayerTiming = () => {
    const dispatch = useDispatch();
    const { prayer } = useAppSelector((state)=>state.prayer);
    useEffect(()=>{
        dispatch(FetchPrayer());
},[dispatch]);

const convertToPM = (time) => {
    return moment(time, 'HH:mm').format('h:mm A');
}

  return (
    <section className="gap no-top">
    <div className="container">
        <div className="namaz-timing">
            <div className="namaz-time">
                <img src="assets/img/namaz-time-icon-1.png" alt="icon" />
                <h4>Fajr</h4>
                <h5>{prayer?.data?.timings.Fajr} AM
                {/* <span>Iqamah:4:15 AM</span> */}
                </h5>
            </div>
            <div className="namaz-time">
                <img src="assets/img/namaz-time-icon-2.png" alt="icon" />
                <h4>Zuhr</h4>
                <h5>{prayer?.data?.timings.Dhuhr} PM
                {/* <span>Iqamah:1:30 PM</span> */}
                </h5>
            </div>
            <div className="namaz-time">
                        <img src="assets/img/namaz-time-icon-3.png" alt="icon" />
                        <h4>Asr</h4>
                        <h5>{convertToPM(prayer?.data?.timings.Asr)}
                        {/* <span>Iqamah:7:30 PM</span> */}
                        </h5>
                    </div>
                    <div className="namaz-time">
                        <img src="assets/img/namaz-time-icon-4.png" alt="icon" />
                        <h4>Magrib</h4>
                        <h5>{convertToPM(prayer?.data?.timings.Maghrib)}
                        {/* <span>Iqamah:9:01 PM</span> */}
                        </h5>
                    </div>
                    <div className="namaz-time">
                        <img src="assets/img/namaz-time-icon-5.png" alt="icon" />
                        <h4>Isha</h4>
                        <h5>{convertToPM(prayer?.data?.timings.Isha)} 
                        {/* <span>Iqamah:10:45 PM</span> */}
                        </h5>
                    </div>
            <div className="namaz-time">
                <img src="assets/img/namaz-time-icon-6.png" alt="icon"  />
                <h4>Jummah</h4>
                <h5>01:10 PM
                    {/* <span>Iqamah:02:45 PM</span> */}
                    </h5>
            </div>
        </div>
    </div>
</section>
  )
}

export default PrayerTiming