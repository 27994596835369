/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import OwlCarousel from 'react-owl-carousel';


const Slider = () => {
  return (
    <OwlCarousel
      className="slider-home-1 owl-carousel owl-theme"
      loop
      items={1} 
      margin={10}
      autoplay 
      autoplayTimeout={3000}
      
      
     
      
    >
      <div className="hero-section item">
            <img src="assets/img/hero-img.jpg" alt="hero-img" className="hero-img-style" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="hero-text">
                            <h1>Allah is the Best of Providers</h1>
                            <p>When things are too hard to handle, retreat & count your blessings instead</p>
                            <a href="#" className="btn">Download App</a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="hero-img">
                            <img src="assets/img/hero-img-1.png" alt="hero-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="hero-section item">
            <img src="assets/img/hero-img-back-1.jpg" alt="hero-img" className="hero-img-style" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="hero-text">
                            <h1>Allah is the Best of Providers</h1>
                            <p>When things are too hard to handle, retreat & count your blessings instead</p>
                            <a href="#" className="btn">Download App</a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="hero-img">
                            <img src="assets/img/hero-img-1.png" alt="hero-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="hero-section item">
            <img src="assets/img/hero-img-back-2.jpg" alt="hero-img" className="hero-img-style" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="hero-text">
                            <h1>Allah is the Best of Providers</h1>
                            <p>When things are too hard to handle, retreat & count your blessings instead</p>
                            <a href="#" className="btn">Download App</a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="hero-img">
                            <img src="assets/img/hero-img-1.png" alt="hero-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </OwlCarousel>
    
  )
}

export default Slider