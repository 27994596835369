/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import AllRouter from "./Routers";
import { Footer, Header, Preloader } from "./components";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Set loading to false after 2000 milliseconds (adjust as needed)

    return () => clearTimeout(timer);
  }, []);

  // Empty dependency array ensures this effect runs only once on mount

  return (
    <>
      {loading ? <Preloader /> : 
      <>
      <Header />
      <AllRouter />
      <Footer />
      </>
      
      }
      
    </>
  );
}

export default App;
