/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Create from './Create'

const Audio = () => {
  return (
    <section className="listen-holy" style={{ backgroundImage: "url(assets/img/holy-quran.jpg)"  }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 ">
            <div className="heading two">
              <p>Listen Holy Quran</p>
              <h2>Recite & Listen the Holy Quran Online</h2>
              {/* <h6>Duis aute irure dolor in reprehenit in voluptate velit esse cillum dolo re eu fugiat nulla pariatur.</h6>
               */}
             
            </div>
          </div>
          <div className="col-lg-8 mt-5" style={{position:"relative"}}>
          <div className="audio-card" >
                <Create />
  
              </div>

          </div>
          {/* <div className="offset-lg-1 col-lg-5">
            <div className="support-us" style={{ backgroundImage: "url(assets/img/color-bg.jpg)" }}>
              <h4>Support us, we need your help.</h4>
              <div className="progressbar">
                <div className="circle" data-percent="85">
                  <div>85%</div>
                </div>
              </div>
              <h6>$71,000</h6>
              <span>Donation Collected</span>
              <a href="#" className="btn">Donate Now</a>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default Audio 