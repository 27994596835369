import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchRequest } from "../Utilities/Request";

export const FetchPrayer = createAsyncThunk('prayer/list', async()=>{
    return await fetchRequest("https://api.aladhan.com/v1/timings/04-04-2024?latitude=34.359688&longitude=73.471054&method=1")
    .then ((response)=>{
        return response.data;
    }).catch((error)=>{
        console.log("error get")
        return[];
    })
})

const initialState={
    loading: null,
    prayer:[]
}
const PrayerSlice =  createSlice({
    name: 'prayer',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder.addCase(FetchPrayer.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(FetchPrayer.fulfilled , (state,action) =>{
            state.prayer = action.payload;
            state.loading = false;
        });
        builder.addCase(FetchPrayer.rejected , (state)=>{
            state.loading = false;
        });

        
    }

});
export default PrayerSlice;