import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE , persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import PrayerSlice from "./Slices/Prayer";
import RecitersName from "./Slices/RecitersName";
const persistConfig = {
    key: "root",
    version: 1,
    storage,
};
const reducers = combineReducers({
   prayer:PrayerSlice.reducer,
   recitersName:RecitersName.reducer,
   
});


const persistedReducer =  persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
export default store;