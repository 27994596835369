/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Grid } from 'react-loader-spinner';

const Preloader = () => {
  const [loading, setLoading] = useState(true);
  return (
    <>
   
    <div className="preloader" >
    <div className="loader">
    <Grid
        visible={loading}
        type="SixDots"
        color="#000000"
        height={80}
        width={80}
      />
    </div>
  </div>
  </>
  )
}

export default Preloader