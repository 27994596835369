/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

const Navigating = () => {
  const [countdown, setCountdown] = useState({
    days: 80,
    hours: 0,
    minutes: 40,
    seconds: 0
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Calculate time remaining
      const now = new Date().getTime();
      const eventDate = new Date("Dec 25, 2024 17:00:00").getTime();
      const distance = eventDate - now;

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the countdown state
      setCountdown({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      });

      // If the countdown is over, clearInterval
      if (distance < 0) {
        clearInterval(intervalId);
        setCountdown({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        });
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <section className="section-navigating gap" style={{backgroundImage: "url(assets/img/background-navigating.jpg)"}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3 col-6">
            <div className="img-navigating hoverimg">
              <figure>
                <img src="assets/img/navigating-1.jpg" alt="img" />
              </figure>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="img-navigating two hoverimg">
              <figure>
                <img src="assets/img/navigating-2.jpg" alt="img" />
              </figure>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="navigating-life">
              <div className="d-flex align-items-center">
                <div className="location">
                  <i className="fa-regular fa-clock"></i><span>Dec 25, 2024 @5:00 PM</span>
                </div>
                <div className="location">
                  <i>
                    {/* eslint-disable-next-line */}
                    <svg width="24px" height="24px" viewBox="-4 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-104.000000, -411.000000)" fill="#007d3a">
                          <path d="M116,426 C114.343,426 113,424.657 113,423 C113,421.343 114.343,420 116,420 C117.657,420 119,421.343 119,423 C119,424.657 117.657,426 116,426 L116,426 Z M116,418 C113.239,418 111,420.238 111,423 C111,425.762 113.239,428 116,428 C118.761,428 121,425.762 121,423 C121,420.238 118.761,418 116,418 L116,418 Z M116,440 C114.337,440.009 106,427.181 106,423 C106,417.478 110.477,413 116,413 C121.523,413 126,417.478 126,423 C126,427.125 117.637,440.009 116,440 L116,440 Z M116,411 C109.373,411 104,416.373 104,423 C104,428.018 114.005,443.011 116,443 C117.964,443.011 128,427.95 128,423 C128,416.373 122.627,411 116,411 L116,411 Z">
                          </path>
                        </g>
                      </g>
                    </svg>
                  </i><span>Islamabad, Pakistan</span>
                </div>
              </div>
              <h2>Navigating Life's Journey with Faith</h2>
              <div id="countdown">
                <ul>
                <li><span id="days">{countdown.days}</span>days</li>
            <li><span id="hours">{countdown.hours}</span>Hour</li>
            <li><span id="minutes">{countdown.minutes}</span>Min</li>
            <li className="mb-0"><span id="seconds">{countdown.seconds}</span>Sec</li>
                </ul>
              </div>
              <div className="d-flex align-items-center">
                <a href="#" className="btn">Join Now</a>
                <div className="video">
                  <a data-fancybox="" href="https://www.youtube.com/watch?v=1La4QzGeaaQ">
                    <i>
                      <svg width="15" height="22" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 8.5L0.5 0.272758L0.5 16.7272L11 8.5Z" fill="#fff"></path>
                      </svg>
                    </i>Watch Video</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Navigating;
