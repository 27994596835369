import React from 'react'
import { Route ,Routes  } from "react-router-dom"
import { Home } from '../Pages'



const AllRouter = () => {
  return (
    <Routes>
        <Route  path="/" exact element={<Home />} />
        <Route  path="/home" exact element={<Home />} />

    </Routes>
  )
}

export default  AllRouter