/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const Feature = () => {
  return (
    
    <section className="our-courses-section gap" style={{backgroundImage: "url(assets/img/courses-back.png)"}}>
    <div className="container">
        <div className="heading">
            <img src="assets/img/favicon.png" alt="icon"  />
            <p>Quran Reading and Listening</p>
            <h2>App Features</h2>
        </div>
        <div className="row">
            <div className="col-lg-3 col-sm-6">
                <div className="courses">
                    <div className="courses-img">
                        <figure>
                            <img src="assets/img/Juzz.png" alt="img" />
                        </figure>
                        <a href="#"><i className="fa-solid fa-arrow-right"></i></a>
                    </div>
                    <div className="scholar">
                        <img src="assets/img/favicon.png" alt="img" />
                        <div>
                            <h4>Juzz</h4>
                            
                        </div>
                    </div>
                  
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
                <div className="courses">
                    <div className="courses-img">
                        <figure>
                            <img src="assets/img/Surah.png" alt="img" />
                        </figure>
                        <a href="#"><i className="fa-solid fa-arrow-right"></i></a>
                    </div>
                    <div className="scholar">
                        <img src="assets/img/favicon.png" alt="img" />
                        <div>
                            <h4>Surah</h4>
                           
                        </div>
                    </div>
                   
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
                <div className="courses mb-sm-0">
                    <div className="courses-img">
                        <figure>
                            <img src="assets/img/Reading.png" alt="img" />
                        </figure>
                        <a href="#"><i className="fa-solid fa-arrow-right"></i></a>
                    </div>
                    <div className="scholar">
                        <img src="assets/img/favicon.png" alt="img" />
                        <div>
                            <h4>Reading</h4>
                           
                        </div>
                    </div>
                   
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
                <div className="courses mb-0">
                    <div className="courses-img">
                        <figure>
                            <img src="assets/img/Listening.png" alt="img" />
                        </figure>
                        <a href="#"><i className="fa-solid fa-arrow-right"></i></a>
                    </div>
                    <div className="scholar">
                        <img src="assets/img/favicon.png" alt="img" />
                        <div>
                            <h4>Habib Al Noor</h4>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
        <div className="logos-heading">
            <h3>Download our App <span>From PlayStore and Apple Store.</span></h3>
            <ul className="logos">
                <li><img src="assets/img/logos-1.png" alt="icon" /></li>
                <li><img src="assets/img/logos-2.png" alt="icon" /></li>
                
            </ul>
        </div>
    </div>
</section>

  )
}

export default Feature