/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const IntroSection = () => {
  return (
    <section className="position-relative">
    <div className="container">
        <div className="heading">
            <img src="assets/img/favicon.png" alt="icon" />
            <p>Welcome to the QuranicNoor</p>
            <h2>Now You can Enjoy Reading and Listening Quran</h2>
        </div>
        <div className="row">
            <div className="col-lg-5">
                <div className="islamic-history">
                    <p>Discover the Quran App: your digital gateway to the sacred text of Islam. Access the holy verses, translations, and interpretations at your fingertips. Navigate seamlessly through chapters, bookmark your favorite passages, and delve into the profound wisdom of the Quran anytime, anywhere. Experience spirituality with convenience like never before.</p>
                    <ul className="list">
                        <li>Quran audio playback</li>
                        <li>Reading Quran Online</li>
                        <li>translations / tafsir </li>
                        <li>Word by word with Juz and Suparah</li>
                    </ul>
                    <a href="#" className="btn">Download QuranicNoor App</a>
                </div>
            </div>
            <div className="col-lg-7">
                <div className="row align-items-end">
                    <div className="col-lg-5 col-6">
                        <div className="circle-text">
                            <img src="assets/img/text-circle.png" className="circle-text-img" alt="circle-img" />
                            <figure className="circle-img">
                                <img src="assets/img/circle-img.png" alt="circle-img" />
                            </figure>
                        </div>
                        <div className="real-history-book hoverimg">
                            <figure>
                                <img src="assets/img/real-history-book.jpg" alt="img" />
                            </figure>
                        </div>
                    </div>
                    <div className="col-lg-7 col-6">
                        <div className="real-history-book two hoverimg">
                            <figure>
                                <img src="assets/img/real-history-boy.jpg" alt="real-history-boy" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default IntroSection